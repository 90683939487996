import { BlogPost } from "@/components/blog/types";

// Dados fictícios para demonstração
export const MOCK_BLOG_POSTS: BlogPost[] = [
  {
    id: "1",
    title: "Mais de 20 concursos previstos para março de 2025: veja lista",
    summary: "Confira a lista completa dos principais concursos públicos que serão realizados em março de 2025, com vagas para todos os níveis.",
    content: "Em março de 2025, diversos órgãos públicos estarão com inscrições abertas para concursos. Destacam-se vagas para o Banco do Brasil, Polícia Federal, INSS e tribunais regionais.\n\nOs salários variam de R$ 2.500 a R$ 18.000, dependendo do cargo e nível de escolaridade exigido. É importante ficar atento aos prazos de inscrição e requisitos específicos de cada certame.\n\nPrepare-se com antecedência para garantir sua aprovação!",
    author: "Mateus Carvalho",
    authorAvatar: "/lovable-uploads/a19bb9dc-c459-47ab-89f0-7addc6bbed15.jpg",
    commentCount: 25,
    likesCount: 78,
    createdAt: "2023-02-15T14:30:00Z",
    slug: "concursos-previstos-marco-2025",
    category: "Concursos",
    region: "nacional",
    tags: ["concursos", "editais", "2025"],
    metaDescription: "Confira a lista completa dos principais concursos públicos previstos para março de 2025, com vagas para todos os níveis de escolaridade.",
    featuredImage: "/lovable-uploads/1a751e43-0164-4c7d-bcb7-8afe675bfb25.jpg",
    readingTime: "5",
    featured: true
  },
  {
    id: "2",
    title: "Concurso INSS de nível médio tem novo edital previsto? Veja!",
    summary: "Saiba se haverá novo concurso para o INSS em 2025 para cargos de nível médio e quais as expectativas para o edital.",
    content: "O Instituto Nacional do Seguro Social (INSS) deverá publicar novo edital para cargos de nível médio ainda em 2025. A previsão é de que sejam oferecidas cerca de 7.500 vagas em todo o território nacional.\n\nSegundo informações do Ministério da Previdência Social, o novo concurso visa suprir o déficit de servidores causado por aposentadorias nos últimos anos.\n\nO salário inicial para o cargo de Técnico do Seguro Social deve ficar em torno de R$ 5.900, além de benefícios.",
    author: "Julia Seixas",
    authorAvatar: "/lovable-uploads/a63635e0-17bb-44d0-b68a-fb02fd8878d7.jpg",
    commentCount: 42,
    likesCount: 95,
    createdAt: "2023-02-14T10:45:00Z",
    slug: "concurso-inss-nivel-medio-2025",
    category: "Concursos",
    region: "federal",
    state: "DF",
    tags: ["INSS", "nível médio", "edital"],
    metaDescription: "Confira as informações sobre o possível novo concurso do INSS para cargos de nível médio previsto para 2025.",
    featuredImage: "/lovable-uploads/72f4e3ba-f775-45ec-a63f-d01db14a5b60.jpg",
    readingTime: "4"
  },
  {
    id: "3",
    title: "Resultado final do CNU é divulgado: veja como acessar as listas",
    summary: "O Concurso Nacional Unificado (CNU) divulgou o resultado final. Saiba como consultar a sua classificação e os próximos passos.",
    content: "O Ministério da Gestão e Inovação divulgou nesta semana o resultado final do Concurso Nacional Unificado (CNU). Os candidatos já podem consultar sua classificação pelo site oficial ou pelo aplicativo.\n\nAs nomeações devem começar em abril, com cursos de formação previstos para maio. É importante que os aprovados mantenham seus dados atualizados no sistema.\n\nO CNU ofereceu mais de 6.500 vagas em diversos órgãos federais, com salários que variam de R$ 5.000 a R$ 22.000.",
    author: "Carlos Mendes",
    authorAvatar: "/lovable-uploads/bb003461-c7e3-466c-8bbd-73478baae87b.jpg",
    commentCount: 67,
    likesCount: 143,
    createdAt: "2023-02-13T16:20:00Z",
    slug: "resultado-final-cnu-divulgado",
    category: "Concursos",
    region: "federal",
    tags: ["CNU", "resultado", "aprovados"],
    metaDescription: "Resultado final do Concurso Nacional Unificado foi divulgado. Veja como consultar sua classificação e os próximos passos para os aprovados.",
    featuredImage: "/lovable-uploads/343607f4-044c-4c9f-8cad-1ccc0760d766.jpg",
    readingTime: "6",
    featured: true
  },
  {
    id: "4",
    title: "Concurso STM: publicado edital para técnicos e analistas",
    summary: "Superior Tribunal Militar lança edital com vagas para técnicos e analistas judiciários em diversas especialidades.",
    content: "O Superior Tribunal Militar (STM) publicou edital de concurso público para preenchimento de vagas de técnicos e analistas judiciários. As inscrições estarão abertas a partir da próxima semana.\n\nAs provas estão previstas para maio de 2025, com possibilidade de nomeação ainda no mesmo ano. Os salários iniciais variam de R$ 8.500 a R$ 13.900, dependendo do cargo.\n\nEntre as especialidades disponíveis estão áreas de Direito, Tecnologia da Informação, Contabilidade e Administração.",
    author: "Ana Oliveira",
    commentCount: 31,
    likesCount: 56,
    createdAt: "2023-02-12T09:15:00Z",
    slug: "concurso-stm-edital-tecnicos-analistas",
    category: "Concursos",
    region: "federal",
    state: "DF",
    tags: ["STM", "judiciário", "analista", "técnico"],
    metaDescription: "Superior Tribunal Militar publica edital para concurso público com vagas para técnicos e analistas judiciários em diversas especialidades.",
    featuredImage: "/lovable-uploads/7f20742a-1d1f-424b-9f56-3cece0204c7b.jpg",
    readingTime: "5"
  },
  {
    id: "5",
    title: "Concurso PF Administrativo: confira as notas de corte por estado",
    summary: "Foram divulgadas as notas de corte preliminares do concurso para Agente Administrativo da Polícia Federal por estado.",
    content: "A Polícia Federal divulgou as notas de corte preliminares do concurso para Agente Administrativo, separadas por estado e por cota. As maiores concorrências foram registradas em Brasília, Rio de Janeiro e São Paulo.\n\nOs candidatos que atingiram a nota mínima serão convocados para a próxima fase, prevista para ocorrer em abril de 2025. O resultado final deve ser divulgado em junho.\n\nO cargo de Agente Administrativo tem salário inicial de R$ 4.700, além de benefícios como auxílio-alimentação.",
    author: "Pedro Santos",
    commentCount: 48,
    likesCount: 82,
    createdAt: "2023-02-11T14:40:00Z",
    slug: "concurso-pf-administrativo-notas-corte",
    category: "Concursos",
    region: "federal",
    tags: ["PF", "administrativo", "notas de corte"],
    metaDescription: "Confira as notas de corte preliminares do concurso para Agente Administrativo da Polícia Federal, divididas por estado e modalidade de concorrência.",
    featuredImage: "/lovable-uploads/98b03e84-986d-4ef1-955d-f92b9422fc94.png",
    readingTime: "7",
    featured: true
  },
  {
    id: "6",
    title: "QC FOLIA: estude de graça para Banco do Brasil, PF e CNU",
    summary: "O BomEstudo preparou materiais gratuitos para você estudar durante o Carnaval para os principais concursos do ano.",
    content: "Aproveite o feriado de Carnaval para adiantar seus estudos com os materiais gratuitos do QC FOLIA do BomEstudo. Preparamos conteúdos específicos para os concursos do Banco do Brasil, Polícia Federal e CNU.\n\nEstão disponíveis videoaulas, resumos e simulados que podem ser acessados pela plataforma ou baixados para estudo offline. Para ter acesso, basta se cadastrar gratuitamente em nosso site.\n\nNão perca tempo e garanta sua aprovação estudando com materiais de qualidade!",
    author: "Fernanda Lima",
    commentCount: 22,
    likesCount: 65,
    createdAt: "2023-02-10T11:30:00Z",
    slug: "qc-folia-estudos-gratuitos-concursos",
    category: "Dicas de Estudo",
    region: "nacional",
    tags: ["material gratuito", "Banco do Brasil", "PF", "CNU"],
    metaDescription: "Aproveite o feriado de Carnaval para estudar com materiais gratuitos do BomEstudo para os principais concursos do ano.",
    featuredImage: "/lovable-uploads/90c45412-9b3b-40db-b562-ca8dc786ab82.png",
    readingTime: "3"
  },
  {
    id: "7",
    title: "Concurso PF: ciclo básico de matérias para estudar para a prova",
    summary: "Saiba quais são as matérias fundamentais para se preparar para o concurso da Polícia Federal em 2025.",
    content: "Para quem deseja ingressar na Polícia Federal, é fundamental estabelecer um ciclo básico de estudos que contemple as principais disciplinas cobradas no certame.\n\nAs matérias essenciais incluem Direito Constitucional, Direito Administrativo, Direito Penal, Legislação Especial, Português, Raciocínio Lógico e Informática.\n\nRecomenda-se dedicar pelo menos 3 horas diárias aos estudos, com foco especial nas disciplinas com maior peso na prova. Resolver questões de concursos anteriores também é uma estratégia eficaz para se familiarizar com o estilo da banca.",
    author: "Julia Seixas",
    authorAvatar: "/lovable-uploads/a63635e0-17bb-44d0-b68a-fb02fd8878d7.jpg",
    commentCount: 39,
    likesCount: 87,
    createdAt: "2023-02-09T13:50:00Z",
    slug: "ciclo-basico-materias-concurso-pf",
    category: "Dicas de Estudo",
    region: "federal",
    tags: ["PF", "estratégia de estudos", "matérias"],
    metaDescription: "Confira o ciclo básico de matérias que você deve estudar para se preparar adequadamente para o concurso da Polícia Federal.",
    featuredImage: "/lovable-uploads/94836d04-1225-493e-a113-36572286edcd.png",
    readingTime: "8"
  },
  {
    id: "8",
    title: "Após concursos, Correios acumulam 10,6 mil cargos vagos",
    summary: "Mesmo após a realização de concursos, os Correios ainda possuem mais de 10 mil cargos não preenchidos em todo o Brasil.",
    content: "Apesar da realização de concursos públicos nos últimos anos, os Correios ainda enfrentam um déficit de mais de 10.600 funcionários em todo o território nacional. A informação foi divulgada pelo sindicato da categoria.\n\nA situação tem gerado sobrecarga para os servidores ativos e afetado a qualidade dos serviços prestados à população. As regiões Norte e Nordeste são as mais prejudicadas pela falta de pessoal.\n\nA empresa estatal informou que deve realizar novo concurso ainda em 2025, com previsão de mais de 3.000 vagas para diversos cargos.",
    author: "Ricardo Almeida",
    commentCount: 54,
    likesCount: 76,
    createdAt: "2023-02-08T10:20:00Z",
    slug: "correios-cargos-vagos-apos-concursos",
    category: "Notícias",
    region: "nacional",
    tags: ["Correios", "cargos vagos", "serviço público"],
    metaDescription: "Mesmo após a realização de concursos, os Correios ainda possuem mais de 10 mil cargos não preenchidos em todo o território brasileiro.",
    readingTime: "6"
  },
  {
    id: "9",
    title: "Concurso Petrobras 2025: edital de nível superior ganha força",
    summary: "Aumentam as expectativas para novo concurso da Petrobras com vagas de nível superior e salários atrativos.",
    content: "A Petrobras deve publicar em breve o edital para seu novo concurso público voltado a cargos de nível superior. De acordo com informações da própria estatal, serão oferecidas aproximadamente 1.800 vagas em diversas áreas.\n\nEntre as formações mais demandadas estão Engenharia (Química, Civil, Mecânica, de Petróleo), Geologia, Ciência da Computação e Administração. Os salários iniciais devem variar de R$ 11.500 a R$ 14.800.\n\nA banca organizadora deve ser definida nas próximas semanas, com previsão de aplicação das provas para o segundo semestre de 2025.",
    author: "Roberta Souza",
    commentCount: 36,
    likesCount: 92,
    createdAt: "2023-02-07T15:15:00Z",
    slug: "concurso-petrobras-2025-nivel-superior",
    category: "Concursos",
    region: "nacional",
    tags: ["Petrobras", "nível superior", "edital"],
    metaDescription: "Aumentam as expectativas para novo concurso da Petrobras com vagas de nível superior em diversas áreas e salários atrativos.",
    readingTime: "5"
  },
  {
    id: "10",
    title: "Sem concurso, INAI Brasil abre 1.073 vagas em diversos cargos",
    summary: "Instituto Nacional de Assuntos Indígenas abre vagas temporárias sem necessidade de concurso público. Confira como se candidatar.",
    content: "O Instituto Nacional de Assuntos Indígenas (INAI Brasil) divulgou processo seletivo simplificado para contratação de 1.073 profissionais em caráter temporário. As vagas não exigem aprovação em concurso público tradicional.\n\nAs oportunidades estão distribuídas em todo o país, com foco nas regiões Norte e Centro-Oeste. Os salários variam de R$ 3.800 a R$ 8.500, dependendo do cargo e da qualificação exigida.\n\nAs inscrições poderão ser realizadas pelo site do instituto a partir da próxima semana. A seleção será feita por análise curricular e entrevista.",
    author: "Gustavo Martins",
    commentCount: 28,
    likesCount: 49,
    createdAt: "2023-02-06T09:40:00Z",
    slug: "inai-brasil-vagas-sem-concurso",
    category: "Concursos",
    region: "norte",
    tags: ["vagas temporárias", "INAI", "processo seletivo"],
    metaDescription: "INAI Brasil abre mais de mil vagas em processo seletivo simplificado sem necessidade de aprovação em concurso público tradicional.",
    readingTime: "4"
  },
  {
    id: "11",
    title: "CNU 2025: veja lista com os órgãos já confirmados no novo edital",
    summary: "Confira quais órgãos federais já confirmaram participação no Concurso Nacional Unificado de 2025.",
    content: "O governo federal já começou a divulgar a lista de órgãos que participarão do Concurso Nacional Unificado (CNU) de 2025. Entre as instituições confirmadas estão o IBGE, Ibama, ICMBio, Ministério da Saúde e Ministério da Educação.\n\nA expectativa é que o novo CNU ofereça mais de 7.000 vagas distribuídas em diversos cargos e áreas de atuação. Os salários devem variar de R$ 4.500 a R$ 21.000, dependendo do nível e da carreira.\n\nO edital deve ser publicado até junho, com aplicação das provas prevista para setembro de 2025 em todas as capitais brasileiras e principais cidades do interior.",
    author: "Mateus Carvalho",
    authorAvatar: "/lovable-uploads/a19bb9dc-c459-47ab-89f0-7addc6bbed15.jpg",
    commentCount: 82,
    likesCount: 156,
    createdAt: "2023-02-05T14:10:00Z",
    slug: "cnu-2025-orgaos-confirmados",
    category: "Concursos",
    region: "federal",
    tags: ["CNU", "concurso unificado", "órgãos federais"],
    metaDescription: "Confira a lista de órgãos federais já confirmados para participação no novo Concurso Nacional Unificado (CNU) previsto para 2025.",
    featuredImage: "/lovable-uploads/a19bb9dc-c459-47ab-89f0-7addc6bbed15.jpg",
    readingTime: "7",
    featured: true
  },
  {
    id: "12",
    title: "Concurso Sefaz RJ dá para conciliar com o concurso Sefaz PR?",
    summary: "Análise sobre a viabilidade de se preparar simultaneamente para os concursos das Secretarias de Fazenda do Rio de Janeiro e do Paraná.",
    content: "Muitos candidatos se perguntam se é possível conciliar a preparação para os concursos da Sefaz RJ e Sefaz PR, que devem ocorrer em datas próximas em 2025. A resposta depende de diversos fatores.\n\nPositivamente, os editais têm cerca de 70% de conteúdos coincidentes, principalmente nas disciplinas de Direito Tributário, Contabilidade e Auditoria. No entanto, as legislações específicas de cada estado diferem significativamente.\n\nEspecialistas recomendam focar nas matérias comuns e reservar tempo específico para estudar as particularidades de cada certame. É fundamental também verificar se as datas das provas não coincidirão.",
    author: "Julia Seixas",
    authorAvatar: "/lovable-uploads/a63635e0-17bb-44d0-b68a-fb02fd8878d7.jpg",
    commentCount: 33,
    likesCount: 61,
    createdAt: "2023-02-04T11:55:00Z",
    slug: "conciliar-concursos-sefaz-rj-pr",
    category: "Dicas de Estudo",
    region: "sudeste",
    state: "RJ",
    tags: ["Sefaz", "estratégia", "concursos simultâneos"],
    metaDescription: "Saiba se é possível conciliar a preparação para os concursos das Secretarias de Fazenda do Rio de Janeiro e do Paraná em 2025.",
    readingTime: "6"
  }
];
